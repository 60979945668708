import { useEffect, useRef, useState } from 'react'
import { Icon, ModalOTPUnipay } from '@smu-chile/pkg-unimarc-components'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import {
  isValidArrayWithData,
  postPreAutorizationPaymentUnipay,
  postUnipayValidateSecurityCode,
  replaceStrings,
  sleep,
  trigger,
  useCheckoutV2,
  useNewCartUnimarc
} from '@smu-chile/pkg-unimarc-hooks'
import { useCheckoutConfig } from 'hooks'
import {
  CODE_UNIPAY_WITH_INSTALLMENTS,
  ERROR_MESSAGE_UNIPAY_4,
  TOAST_MESSAGES,
  TOAST_MESSAGES_MODAL_UNIPAY_OTP,
  UNIPAY_EXCEPTIONS
} from 'shared/constants'
import {
  MODAL_OTP_UNIPAY_IDS,
  MODAL_OTP_UNIPAY_WRAPPER_CONFIGS
} from './constants'
import { addPaymentInfoEvent } from '../PayBtn/helpers'
import { IOtpData } from '../PaymentMethods'
import { ModalUnipayTotal } from '../PaymentMethods/ModalUnipay/ModalUnipayTotal'

interface IModalOTPUnipayWrapperProps {
  data: IOtpData
  handleToggle: () => void
  handleGoBack: () => void
  handleResentCodeUnipay: ({ rut }: { rut: string }) => void
}

export const ModalOTPUnipayWrapper = ({
  data,
  handleToggle,
  handleGoBack,
  handleResentCodeUnipay
}: IModalOTPUnipayWrapperProps) => {
  const [isEnablePayment, setIsEnablePayment] = useState(false)
  const [isConfirmOtpCode, setisConfirmOtpCode] = useState(false)
  const [isLoadingPaymentButton, setIsloadingPaymentButton] = useState(false)
  const [hasCountDown, setHasCountDown] = useState(false)
  const [countDownTimer, setCountDownTimer] = useState('00:00')
  const [otpValue, setOtpValue] = useState('')
  const fieldsetRef = useRef<HTMLFieldSetElement>(null)
  const { data: checkout } = useCheckoutV2()
  const { data: cart } = useNewCartUnimarc()
  const { checkoutConfig } = useCheckoutConfig()

  const isLoading =
    data?.isLoading || isConfirmOtpCode || isLoadingPaymentButton

  const findUniPayValues = checkout?.paymentInfo?.availablePaymentMethods?.find(
    (paymentMethod) => {
      return paymentMethod.id === CODE_UNIPAY_WITH_INSTALLMENTS
    }
  )
  const totalValue = findUniPayValues?.price || checkout?.value

  const handleCleanCodeForm = async () => {
    const inputs = fieldsetRef.current?.querySelectorAll('input')

    for (let i = 0; i < inputs.length; i++) {
      await sleep(5)
      inputs[i].focus()
      const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
        window.HTMLInputElement.prototype,
        'value'
      ).set
      nativeInputValueSetter.call(inputs[i], '')

      const inputEvent = new Event('input', { bubbles: true })
      inputs[i].dispatchEvent(inputEvent)
    }
    inputs[0].focus()
  }

  const handleResendCode = async () => {
    handleResentCodeUnipay({ rut: data?.rut })
    handleCleanCodeForm()
    startCountDown()
    trigger({
      eventType: 'checkoutToast',
      data: {
        portalRootId: 'portal-otp',
        type: 'info',
        show: true,
        toastMessage: TOAST_MESSAGES_MODAL_UNIPAY_OTP.info
      }
    })
  }

  const handleTransaction = async () => {
    try {
      if (!isValidArrayWithData(checkout?.selectedDeliveryWindows)) {
        trigger({
          eventType: 'checkoutToast',
          data: {
            codeError: 'error',
            show: true,
            toastMessage: TOAST_MESSAGES.error_delivery_windows
          }
        })
        return
      }
      setIsloadingPaymentButton(true)
      setIsEnablePayment(false)

      const response = await postPreAutorizationPaymentUnipay({
        data: {
          card: data?.rut,
          rut: data?.rut,
          orderFormId: checkoutConfig?.orderformId,
          orderAmount: replaceStrings(totalValue),
          customerEmail: checkout?.profile?.email,
          paymentSystemInit:
            checkout?.paymentInfo?.selectedPayments?.[0]?.id || '',
          quotas: data?.simulatePaymentData?.quotas?.toString() || '0',
          quotaValue:
            replaceStrings(data?.simulatePaymentData?.quotaValue).toString() ||
            '0',
          otp: otpValue,
          monthLack: data?.simulatePaymentData?.monthLack || '0',
          total:
            replaceStrings(data?.simulatePaymentData?.total).toString() || '0',
          balance: data?.simulatePaymentData?.balance || '0',
          increase: data?.simulatePaymentData?.increase || '0',
          cae: data?.simulatePaymentData?.cae || '0'
        },
        requestOptions: {
          headers: {
            'Allow-Control-Allow-Origin': '*',
            payment_method:
              data?.simulatePaymentData?.quotas > 0
                ? 'unipay-quotas'
                : 'unipay',
            idToken: checkoutConfig.idToken,
            store: checkoutConfig.saleschannel,
            Authorization: `Bearer ${checkoutConfig.caToken}`
          }
        }
      })

      if (response.status !== 200) {
        trigger({
          eventType: 'checkoutToast',
          data: {
            codeError: response?.data?.code || 'error',
            show: true,
            toastMessage: TOAST_MESSAGES.error_transaction
          }
        })
        setIsloadingPaymentButton(false)
        setIsEnablePayment(true)
        handleToggle()
        return
      }
      // trigger add_payment_info tagmanager event to tracking purchase KPI
      addPaymentInfoEvent({
        checkout,
        cart,
        orderformId: checkoutConfig?.orderformId,
        saleschannel: checkoutConfig?.saleschannel
      })
      await sleep(800)
      // prepare url redirect
      const orderId = response?.data?.orderId
      const url = `${process.env.NEXT_PUBLIC_ORDERPLACED}/?og=${orderId}`
      window.location.href = url
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error({ error })
    }
  }

  const handleChangeCodeInput = (value: string) => {
    if (value?.length === 6) {
      setOtpValue(value)
      handleVerificationCode(value)
    } else {
      setIsEnablePayment(false)
    }
  }

  const handleVerificationCode = async (code: string) => {
    setisConfirmOtpCode(true)
    const responseValidationCode = await postUnipayValidateSecurityCode({
      data: {
        token: data?.token,
        rut: data?.rut,
        otp: code
      }
    })

    if (responseValidationCode.status === 204) {
      trigger({
        eventType: 'checkoutToast',
        data: {
          portalRootId: 'portal-otp',
          success: true,
          show: true,
          toastMessage: TOAST_MESSAGES_MODAL_UNIPAY_OTP.success
        }
      })
      setIsEnablePayment(true)
    } else {
      const errorCode = responseValidationCode?.code || ''
      const errorMessage =
        errorCode?.length > 0
          ? UNIPAY_EXCEPTIONS.find((exception) => {
              return exception.code_errors.includes(
                responseValidationCode?.code
              )
            })?.message
          : ''
      trigger({
        eventType: 'checkoutToast',
        data: {
          portalRootId: 'portal-otp',
          codeError: errorCode,
          show: true,
          toastMessage: errorMessage || ERROR_MESSAGE_UNIPAY_4
        }
      })
    }
    setisConfirmOtpCode(false)
  }

  const startCountDown = () => {
    setCountDownTimer('00:30')
    setHasCountDown(true)
  }

  useEffect(() => {
    if (!hasCountDown) {
      startCountDown()
    }
  }, [])

  useEffect(() => {
    // count down timer for resend code
    const interval = setInterval(() => {
      const [minutes, seconds] = countDownTimer.split(':').map(Number)
      if (minutes === 0 && seconds === 0) {
        clearInterval(interval)
        setHasCountDown(false)
      } else {
        const newSeconds = seconds - 1
        setCountDownTimer(
          `${minutes}:${newSeconds.toString().padStart(2, '0')}`
        )
      }
    }, 1000)

    return () => {
      return clearInterval(interval)
    }
  }, [countDownTimer, hasCountDown])

  return (
    <ModalOTPUnipay
      countDownTimer={
        hasCountDown ? `Pide uno nuevo en ${countDownTimer}` : '00:00'
      }
      desktopModalWrapperProps={{
        headerCloseIcon: {
          customSize: 13
        },
        bodyChildrenProps: {
          overflow: 'auto',
          maxHeight: 'initial',
          padding: '20px'
        },
        blockId: MODAL_OTP_UNIPAY_IDS.DESKTOP_MODAL,
        modalConfigsProps: {
          maxHeight: MODAL_OTP_UNIPAY_WRAPPER_CONFIGS.HEIGHT_DESKTOP,
          minHeight: MODAL_OTP_UNIPAY_WRAPPER_CONFIGS.HEIGHT_DESKTOP
        },
        footerChildrenProps: {
          default: false,
          boxShadow: 'initial',
          borderEdge: 'all',
          borderRadius: '0',
          buttonDefaultProps: {
            label: 'Pagar',
            status: isEnablePayment ? 'initial' : 'disabled',
            background: isEnablePayment
              ? getGlobalStyle('--color-primary-red2')
              : undefined,
            topExtraChildren: <ModalUnipayTotal totalValue={totalValue} />
          }
        }
      }}
      fieldsetRef={fieldsetRef}
      handleChangeCodeInput={handleChangeCodeInput}
      handleGoBack={handleGoBack}
      handleResendCode={handleResendCode}
      handleToggle={handleToggle}
      handleValidateOTP={handleTransaction}
      hasCountDown={hasCountDown}
      isConfirming={isConfirmOtpCode}
      isLoading={isLoading}
      mobileModalWrapperProps={{
        headerCloseIconProps: {
          customSize: 13
        },
        headerLeftElement: (
          <Icon
            color={getGlobalStyle('--color-base-black')}
            customId={getBemId(`${MODAL_OTP_UNIPAY_IDS.MOBILE_MODAL}`, 'icon')}
            customSize={20}
            name='ArrowRightNavigate2'
            onClick={handleToggle}
            transform='rotate(180deg)'
          />
        ),
        footerContainerProps: {
          boxShadow: 'initial',
          padding: '16px'
        },
        buttonConfigsProps: {
          status: isEnablePayment ? 'initial' : 'disabled',
          background: isEnablePayment
            ? getGlobalStyle('--color-primary-red2')
            : undefined,
          onClick: handleTransaction,
          isLoading: isLoading || isConfirmOtpCode,
          topExtraChildren: <ModalUnipayTotal totalValue={totalValue} />,
          label: 'Pagar'
        }
      }}
      phone={data?.phone}
    />
  )
}
