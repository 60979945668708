import { useRouter } from 'next/router'
import {
  ICardResponseV2,
  isValidArrayWithData,
  patchPayment,
  useCheckoutV2,
  useQueryClient
} from '@smu-chile/pkg-unimarc-hooks'
import { useCheckoutConfig } from 'hooks/useCheckoutConfig'
import { filterCards } from 'shared/helpers/filterCards'
import { ICheckoutV2Response } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/ICheckoutV2'

export interface IUserSetLastCard {
  hasCleanUrl?: boolean
}

/**
 * Custom hook that sets the last card as the default payment method.
 * @param {Object} options - The options for the hook.
 * @param {boolean} options.hasCleanUrl - Flag indicating whether to clean the URL after setting the last card. Default is true.
 * @returns {Object} - An object containing the handleSetLastCard function.
 */
export const useSetLastCard = ({
  hasCleanUrl = true
}: IUserSetLastCard = {}) => {
  const queryClient = useQueryClient()
  const {
    refetch: refetchCheckout,
    data: newCheckout,
    ...optionsCheckout
  } = useCheckoutV2()
  const { mutateCheckoutConfig } = useCheckoutConfig()
  const { push, query } = useRouter()

  const isLoadingSetLastCard =
    queryClient.getQueryData('isLoadingSetLastCard') || false

  // function to create and delete the query to set the loading
  const handleLoading = (value: boolean) => {
    if (value) {
      // create a new query to set the loading by temporal time
      queryClient.setQueryData('isLoadingSetLastCard', value)
    } else {
      // remove the query to set the loading when the value is false
      queryClient.removeQueries(['isLoadingSetLastCard'])
    }
    mutateCheckoutConfig({ isLoading: value })
  }

  // function to clean the selected payment of the
  // checkout data when user remove all cards
  const handleCleanSelectedPayment = async () => {
    handleLoading(true)
    await patchPayment({ data: [] })
    await refetchCheckout()
    handleLoading(false)
  }

  // function to select the payment method when the user
  // add a new card
  const handleSelectPayment = async (firtsCard) => {
    handleLoading(true)

    const nameType = firtsCard?.type === 'debit' ? 'Débito' : 'Crédito'
    const name = `${firtsCard?.gateway} ${nameType}`
    const total = newCheckout?.value

    const response = await patchPayment({
      data: [
        {
          id: firtsCard?.paymentId,
          name,
          cardId: firtsCard?.id,
          cardType: firtsCard?.type,
          valueUsed: total
        }
      ]
    })

    await refetchCheckout()
    handleLoading(false)

    if (isValidArrayWithData(response?.data) && hasCleanUrl) {
      // clean params to the url
      push('/')
    }
  }

  /**
   * Function that sets the last card as the default payment method.
   * @returns {Promise<void>} - A promise that resolves when the last card is set.
   */
  const handleSetLastCard = async (): Promise<void> => {
    const dataCards = queryClient.getQueryData('/cards') as ICardResponseV2
    const newCheckoutCache = queryClient.getQueryData(
      '/checkoutV2'
    ) as ICheckoutV2Response

    if (optionsCheckout.isLoading && !query?.inscriptionSuccess) {
      handleLoading(true)
      return
    }
    if (newCheckout?.error) {
      handleLoading(false)
      return
    }

    // filter all cards that are not giftcard
    const listCards = filterCards(dataCards?.cards)

    // get selected card
    const isSelectedCard = isValidArrayWithData(
      newCheckoutCache?.paymentInfo?.selectedPayments
    )

    // when user doesnt have anymore cards we settle
    // and empty payment type into the patchPayment service
    if (isSelectedCard && !isValidArrayWithData(listCards)) {
      handleLoading(false)
      await handleCleanSelectedPayment()
      return
    }

    if (!isValidArrayWithData(listCards)) return

    // get the last card with isDefault true
    const isDefaultCard =
      !query?.inscriptionSuccess &&
      listCards?.find((item) => {
        return item?.isDefault
      })

    // get the first card
    const firtsCard = isDefaultCard || listCards?.[0]

    const isCardSelectedInListCards = listCards?.some((item) => {
      return (
        item?.id ===
        newCheckoutCache?.paymentInfo?.selectedPayments?.[0]?.cardId
      )
    })

    // when user has only one card and is the same that
    // is selected we dont do anything
    if (
      isSelectedCard &&
      newCheckoutCache?.paymentInfo?.selectedPayments?.[0]?.cardId ===
        firtsCard?.id &&
      !query?.inscriptionSuccess
    ) {
      handleLoading(false)
      return
    }

    // when user doesnt have one card selected we select the first card
    // when the user finish the inscription we select the first card
    if (
      (!isValidArrayWithData(newCheckoutCache?.paymentInfo?.giftCards) &&
        !isSelectedCard) ||
      query?.inscriptionSuccess ||
      (isSelectedCard && !isCardSelectedInListCards)
    ) {
      await handleSelectPayment(firtsCard)
    }

    handleLoading(false)
  }

  return { handleSelectPayment, handleSetLastCard, isLoadingSetLastCard }
}
