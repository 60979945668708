import {
  Backbone,
  Column,
  Container,
  Row
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'

export const PaymentMethodLoader = () => {
  return (
    <Container
      customHeight='max-content'
      gap={12}
      maxHeight='691px'
      padding='20px'
    >
      <Column
        alignItems='center'
        gap={20}
        padding='16px'
      >
        <Row gap={20}>
          <Row maxWidth='90px'>
            <Backbone
              borderRadius={getGlobalStyle('--border-radius-lg')}
              height={15}
            />
          </Row>
        </Row>
        <Row
          alignItems='center'
          gap={12}
        >
          <Row maxWidth='42px'>
            <Backbone
              borderRadius={getGlobalStyle('--border-radius-full')}
              height={42}
            />
          </Row>
          <Row maxWidth='245px'>
            <Backbone
              borderRadius={getGlobalStyle('--border-radius-full')}
              height={20}
            />
          </Row>
        </Row>
        <Row
          alignItems='center'
          gap={12}
        >
          <Row maxWidth='42px'>
            <Backbone
              borderRadius={getGlobalStyle('--border-radius-full')}
              height={42}
            />
          </Row>
          <Row justifyContent='between'>
            <Row maxWidth='70px'>
              <Backbone
                borderRadius={getGlobalStyle('--border-radius-lg')}
                height={20}
              />
            </Row>

            <Row maxWidth='70px'>
              <Backbone
                borderRadius={getGlobalStyle('--border-radius-lg')}
                height={20}
              />
            </Row>
          </Row>
        </Row>
      </Column>
    </Container>
  )
}
