import Link from 'next/link'

import { Column, MiniFooter } from '@smu-chile/pkg-unimarc-components'
import { isValidArrayWithData } from '@smu-chile/pkg-unimarc-hooks'
import { BigScreen } from '@smu-chile/pkg-unimarc-components/helpers'
import { useCheckoutConfig } from 'hooks'

export const Footer = () => {
  const { checkoutConfig } = useCheckoutConfig()

  if (!isValidArrayWithData(checkoutConfig.footer)) return null

  return (
    <BigScreen>
      <Column alignSelf='end'>
        <MiniFooter
          data={checkoutConfig.footer}
          linkWrapper={Link}
          margin='0'
        />
      </Column>
    </BigScreen>
  )
}
