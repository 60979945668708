import { useEffect, useState } from 'react'
import { Cookies, useCards, useCheckoutV2 } from '@smu-chile/pkg-unimarc-hooks'
import { isValidArrayWithData } from '@smu-chile/pkg-unimarc-components/shared/helpers/isValidArrayWithData'
import { ModalGifcard } from '../ModalGifcard'
import { SelectGifcard } from './SelectGifcard'
import { GifcardSelected } from './GifcardSelected'
import { GifcardNominativa } from './GifcardNominativa'
import { KIND_OF_GIFTCARD } from 'shared/constants'

export interface IGiftCard {
  balance?: string
  cardNumber?: string
  hasEnoughAmount?: boolean
  isActive?: boolean
}

interface GiftcardComponentProps {
  showNotificationBubble: boolean
  handleClickOnBubble: (value: boolean) => void
  handleOpenDeleteGCModal: (card: IGiftCard) => void
}

export const GifcardComponent = ({
  showNotificationBubble,
  handleClickOnBubble,
  handleOpenDeleteGCModal
}: GiftcardComponentProps): React.ReactElement => {
  const [isOpenGiftCardModal, setIsOpenGiftCardModal] = useState(false)
  const [hasGiftCard, setHasGiftCard] = useState(false)

  const { data: checkout } = useCheckoutV2()
  const { data: dataCards } = useCards({ version: 2 })

  const currentGiftCardActive = checkout?.paymentInfo?.giftCards?.find(
    (giftCard) => {
      return giftCard.isActive
    }
  )
  const shouldShowGiftCardNominative =
    !hasGiftCard && isValidArrayWithData(dataCards?.nominatives)
  const isCurrentGiftCardNominative =
    currentGiftCardActive?.giftcardType?.toUpperCase() ===
    KIND_OF_GIFTCARD.NOMINATIVA
  const hasNominativeGiftCard =
    shouldShowGiftCardNominative || isCurrentGiftCardNominative
  const showGiftCardSelected = hasGiftCard && !hasNominativeGiftCard
  const showSelectGiftCard = !hasGiftCard && !hasNominativeGiftCard
  const shouldOpenModalGC = Boolean(Cookies.get('openModalGC')) || false

  const handleToggleGiftCardModal = () => {
    setIsOpenGiftCardModal((prev) => {
      return !prev
    })
    if (showNotificationBubble) handleClickOnBubble(false)
  }

  const handleOpenDeleteGCModalAndCloseCarousel = (card: IGiftCard) => {
    handleOpenDeleteGCModal(card)
    handleToggleGiftCardModal()
  }

  useEffect(() => {
    const newValueHasGiftCard = isValidArrayWithData(
      checkout?.paymentInfo?.giftCards
    )
    if (hasGiftCard !== newValueHasGiftCard) {
      setHasGiftCard(newValueHasGiftCard)
    }
  }, [checkout])

  useEffect(() => {
    if (shouldOpenModalGC) {
      handleToggleGiftCardModal()
      Cookies.remove('openModalGC')
    }
  }, [shouldOpenModalGC])

  return (
    <>
      {isOpenGiftCardModal && (
        <ModalGifcard
          handleOpenDeleteGCModal={handleOpenDeleteGCModalAndCloseCarousel}
          onClose={handleToggleGiftCardModal}
        />
      )}
      {hasNominativeGiftCard && (
        <GifcardNominativa
          handleToggleGiftCardModal={handleToggleGiftCardModal}
        />
      )}
      {showGiftCardSelected && (
        <GifcardSelected
          gifcardData={currentGiftCardActive}
          handleToggleGiftCardModal={handleToggleGiftCardModal}
          totalAmount={checkout?.value}
        />
      )}
      {showSelectGiftCard && (
        <SelectGifcard handleToggleGiftCardModal={handleToggleGiftCardModal} />
      )}
    </>
  )
}
