import { replaceStrings } from '@smu-chile/pkg-unimarc-hooks'

/**
 * Finds the minimum price if there are at least two different prices in the array of payment methods.
 *
 * @param {Object[]} arr - The array of payment methods.
 * @returns {number|undefined} - The minimum price if there are at least two different prices; otherwise, undefined.
 *
 * @example
 * const paymentMethods = [
 *   { id: '1', price: '$10.00' },
 *   { id: '2', price: '$15.00' },
 *   { id: '3', price: '$10.00' },
 * ];
 * const minPrice = getMinPriceIfDifferent(paymentMethods); // Returns 10
 */
export const getMinPriceIfDifferent = (availablePaymentMethods) => {
  // Not enough elements to compare
  if (availablePaymentMethods.length < 2) {
    return undefined
  }

  // Use a set to store unique prices
  const uniquePrices = new Set<number>()

  for (let i = 0; i < availablePaymentMethods.length; i++) {
    const price = replaceStrings(availablePaymentMethods[i]?.price)
    if (!isNaN(price)) {
      // Check if price is a valid number
      uniquePrices.add(price)
    }
  }

  // Convert the set to an array to allow price comparison
  const pricesArray = Array.from(uniquePrices)

  // If there is only one unique price, all prices are equal
  if (pricesArray.length === 1) {
    return undefined // No distinctive minimum price
  }

  // If there are multiple unique prices, find the minimum
  const minPrice = Math.min(...pricesArray)
  return minPrice
}
