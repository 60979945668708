import NextImage from 'next/image'
import { useContentful } from '@smu-chile/pkg-unimarc-hooks'
import { Backbone, Picture, Row } from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { ERROR_404_IMG } from 'shared/constants'
import style from '../ErrorPageV2.module.css'
import { ErrorType } from '..'

interface ErrorImageProps {
  backboneHeight?: number
  backboneWidth?: number
  errorType: string
  imageUrl: string
  imgHeight?: string
  imgWidth?: string
  isMobile?: boolean
}

// get contenful error image
const errorImages: Partial<Record<ErrorType | '500', string>> = {
  '404': ERROR_404_IMG
}

const getErrorImageId = (errorType: ErrorType | '500'): string => {
  return errorImages[errorType] || errorImages['404']
}

export const ErrorImage = ({
  errorType,
  imageUrl,
  isMobile = false,
  imgHeight = '200px',
  imgWidth = '200px',
  backboneHeight = 200,
  backboneWidth = 200
}: ErrorImageProps) => {
  const errorImage = useContentful({
    options: {
      'sys.id': getErrorImageId(errorType as ErrorType)
    },
    type: 'assets'
  })
  const image =
    imageUrl !== ''
      ? imageUrl
      : errorImage?.data?.['items']?.[0]?.fields?.file?.url

  const customClassImage =
    !isMobile && errorType === '500'
      ? {
          customClassName: style['errorPageV2__image--donut']
        }
      : undefined
  return errorImage.isLoading ? (
    <Row maxWidth='250px'>
      <Backbone
        borderRadius={getGlobalStyle('--border-radius-2sm')}
        height={backboneHeight}
        width={backboneWidth}
      />
    </Row>
  ) : (
    <Picture
      alt='imagen de error de un donuts'
      height={imgHeight}
      nextImage={NextImage}
      src={image}
      title='imagen de error de un donuts'
      url={image}
      width={imgWidth}
      {...customClassImage}
    />
  )
}
