import { useEffect, useState } from 'react'
import {
  Column,
  Icon,
  Picture,
  Row,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen,
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import {
  patchGiftcards,
  replaceStrings,
  sleep,
  useCards,
  useCheckoutV2
} from '@smu-chile/pkg-unimarc-hooks'
import { useCheckoutConfig } from 'hooks'
import { getLastFourNumbersOfCard } from 'shared/helpers'
import { KIND_OF_GIFTCARD } from 'shared/constants'

interface GifcardNominativaProps {
  handleToggleGiftCardModal: () => void
}

export const GifcardNominativa = ({
  handleToggleGiftCardModal
}: GifcardNominativaProps): React.ReactElement => {
  const [isSelectedGiftCard, setIsSelectedGiftCard] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [gcSelected, setGcSelected] = useState(null)
  const { mutateCheckoutConfig, getTotalAmountByCards } = useCheckoutConfig()
  const { data: checkout, temporalMutate } = useCheckoutV2()
  const { data: dataCards } = useCards({ version: 2 })

  const hasEnoughAmount = gcSelected?.hasEnoughAmount
  const shouldShowEnoughAmountAlert = !hasEnoughAmount && isSelectedGiftCard

  const borderSelected = isSelectedGiftCard
    ? `1px solid ${getGlobalStyle('--color-primary-light-red')}`
    : undefined

  const handleSelectGiftCard = async () => {
    const isSameGiftcard = checkout?.paymentInfo?.giftCards?.find(
      (giftCard) => {
        return giftCard.cardNumber === gcSelected?.card
      }
    )

    if (isSameGiftcard || isLoading) {
      setIsSelectedGiftCard(true)
      return
    }

    const backupPaymentInfo = JSON.parse(JSON.stringify(checkout.paymentInfo))

    setIsLoading(true)
    setIsSelectedGiftCard(true)
    temporalMutate({
      paymentInfo: {
        ...checkout?.paymentInfo,
        selectedPayments: null,
        giftCards: [
          {
            cardNumber: gcSelected?.card,
            balance: gcSelected?.balance,
            isActive: true,
            hasEnoughAmount: true,
            giftcardType: KIND_OF_GIFTCARD.NOMINATIVA
          }
        ]
      }
    })
    const response = await patchGiftcards({
      data: [
        {
          cardNumber: gcSelected?.card,
          pin: '',
          active: true,
          document: checkout?.profile?.document
        }
      ]
    })
    if (response?.status === 200) {
      sessionStorage.setItem(
        'nominatives',
        JSON.stringify({
          nominatives: dataCards?.nominatives
        })
      )
      temporalMutate({
        paymentInfo: {
          ...checkout?.paymentInfo,
          selectedPayments: null,
          giftCards: response?.data?.giftcards ?? []
        },
        totals: response?.data?.totals,
        value: response?.data?.value
      })
    } else {
      setIsSelectedGiftCard(false)
      temporalMutate({
        paymentInfo: backupPaymentInfo
      })
    }
    setIsLoading(false)
  }

  useEffect(() => {
    if (dataCards?.nominatives) {
      const findGiftCardNominative = checkout?.paymentInfo?.giftCards?.find(
        (giftCard) => {
          return (
            giftCard.isActive &&
            giftCard.giftcardType.toUpperCase() === KIND_OF_GIFTCARD.NOMINATIVA
          )
        }
      )
      if (findGiftCardNominative) {
        setGcSelected({
          balance: findGiftCardNominative.balance,
          card: findGiftCardNominative.cardNumber,
          hasEnoughAmount: findGiftCardNominative.hasEnoughAmount
        })
        setIsSelectedGiftCard(true)
        return
      }
      const getMaxBalance = Math.max(
        ...(dataCards?.nominatives?.map((card) => {
          return replaceStrings(card.balance)
        }) || [])
      )
      const orderGiftCards = Object.assign([], dataCards?.nominatives).reverse()
      const findGiftCard = orderGiftCards?.find((card) => {
        return replaceStrings(card.balance) === getMaxBalance
      })
      const hasEnoughAmount =
        replaceStrings(findGiftCard?.balance) <= replaceStrings(checkout?.value)
      setGcSelected({
        ...findGiftCard,
        hasEnoughAmount
      })
    }
  }, [checkout, dataCards])

  useEffect(() => {
    const handleUpdateLoading = async () => {
      await sleep(100)
      mutateCheckoutConfig({ loadingTransaction: isLoading })
    }

    handleUpdateLoading()
  }, [isLoading])

  return (
    <>
      <BigScreen>
        <Column
          border={borderSelected}
          borderRadius={getGlobalStyle('--border-radius-md')}
          clickable='pointer'
          gap={16}
          id={getBemId('giftcard-nominativa', 'container')}
          onClick={handleSelectGiftCard}
          padding='16px'
        >
          <Row
            clickable='pointer'
            justifyContent='between'
          >
            <Row maxWidth='max-content'>
              <Text
                clickable='pointer'
                fontSize='base'
                fontWeight='medium'
                id={getBemId('giftcard-title', 'container')}
              >
                Mi Giftcard
              </Text>
            </Row>
            <Row
              maxHeight='max-content'
              maxWidth='max-content'
              onClick={(e) => {
                const event = e as unknown as MouseEvent
                event?.stopPropagation()
                handleToggleGiftCardModal()
              }}
            >
              <Text
                clickable='pointer'
                customColor={getGlobalStyle('--color-primary-light-red')}
                fontWeight='medium'
                id={getBemId('gifcard', 'cambiar', 'buttonDesktop')}
                lineHeight='17px'
              >
                Cambiar
              </Text>
            </Row>
          </Row>
          <Row
            alignItems='center'
            clickable='pointer'
            customHeight='42px'
            justifyContent='between'
          >
            <Row
              clickable='pointer'
              gap={12}
              id={getBemId('giftcard-info', 'container')}
              maxWidth='max-content'
            >
              <Picture
                hasURL
                height='42px'
                id={getBemId('giftcard', 'image')}
                src='/images/giftcard_chile.png'
                width='42px'
              />
              <Column
                clickable='pointer'
                gap={4}
                id={getBemId('giftcard-info', 'detail')}
                maxWidth='max-content'
              >
                <Text
                  clickable='pointer'
                  fontSize='base'
                  fontWeight='semibold'
                  id={getBemId('giftcard', 'number')}
                >
                  ****{getLastFourNumbersOfCard(gcSelected?.card)}
                </Text>
                <Text
                  clickable='pointer'
                  color='grayNeutral'
                  fontSize='md'
                  id={getBemId('giftcard', 'balance')}
                >
                  Saldo: {gcSelected?.balance}
                </Text>
              </Column>
            </Row>
            <Row maxWidth='max-content'>
              <Text
                clickable='pointer'
                fontSize='base'
                fontWeight='medium'
                id={getBemId('giftcard', 'buy', 'amount')}
              >
                {getTotalAmountByCards()}
              </Text>
            </Row>
          </Row>
          {shouldShowEnoughAmountAlert && (
            <Row
              alignItems='center'
              gap={5}
              id={getBemId('giftcard-info', 'container')}
            >
              <Icon
                color={getGlobalStyle('--color-feedback-info')}
                customSize={24}
                name='InfoFillThin'
              />
              <Text
                fontSize='md'
                id={getBemId('giftcard-info', 'message')}
              >
                El saldo no es suficiente, por favor escoge otra Giftcard o
                medio de pago.
              </Text>
            </Row>
          )}
        </Column>
      </BigScreen>
      <SmallScreen>
        <Column
          border={borderSelected}
          borderRadius={getGlobalStyle('--border-radius-md')}
          gap={12}
          id={getBemId('giftcard-nominativa', 'container')}
          onClick={handleSelectGiftCard}
          padding='16px'
        >
          <Column gap={8}>
            <Row justifyContent='between'>
              <Row maxWidth='max-content'>
                <Text
                  fontSize='base'
                  fontWeight='medium'
                >
                  Mi Giftcard
                </Text>
              </Row>
              <Text
                clickable='pointer'
                customColor={getGlobalStyle('--color-primary-light-red')}
                fontWeight='medium'
                id={getBemId('gifcard', 'cambiar', 'buttonMobile')}
                lineHeight='17px'
                onClick={handleToggleGiftCardModal}
              >
                Cambiar
              </Text>
            </Row>
            <Row
              alignItems='center'
              customHeight='37px'
              id={getBemId('giftcard-info', 'container')}
              justifyContent='between'
            >
              <Row
                alignItems='center'
                gap={12}
                maxWidth='max-content'
              >
                <Picture
                  hasURL
                  height='30px'
                  id={getBemId('giftcard', 'image')}
                  src='/images/giftcard_chile.png'
                  width='30px'
                />
                <Column
                  gap={4}
                  maxWidth='max-content'
                >
                  <Text
                    fontSize='md'
                    fontWeight='medium'
                  >
                    ****
                    {getLastFourNumbersOfCard(gcSelected?.card)}
                  </Text>
                  <Text
                    color='grayNeutral'
                    fontSize='sm'
                  >
                    Saldo: {gcSelected?.balance}
                  </Text>
                </Column>
              </Row>
              <Row maxWidth='max-content'>
                <Text
                  fontSize='base'
                  fontWeight='medium'
                >
                  {getTotalAmountByCards()}
                </Text>
              </Row>
            </Row>
          </Column>
          {shouldShowEnoughAmountAlert && (
            <Row
              alignItems='center'
              gap={5}
              id={getBemId('giftcard-info', 'container')}
              padding='2px 0'
            >
              <Row
                customHeight='20px'
                customWidth='20px'
                id={getBemId('giftcard-info', 'icon')}
                maxWidth='max-content'
              >
                <Icon
                  color={getGlobalStyle('--color-feedback-info')}
                  customSize={20}
                  name='InfoFillThin'
                />
              </Row>
              <Text
                fontSize='sm'
                id={getBemId('giftcard-info', 'message')}
              >
                El saldo no es suficiente, por favor escoge otra Giftcard o
                medio de pago.
              </Text>
            </Row>
          )}
        </Column>
      </SmallScreen>
    </>
  )
}
