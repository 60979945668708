import {
  Container,
  Column,
  Text,
  Spacer,
  Row
} from '@smu-chile/pkg-unimarc-components'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'
import Lottie from 'lottie-react'
import useTransactionAnimation from './useTransactionAnimation'

export const TransitionPayBtn = () => {
  const { isMobile } = useMobile()
  const { transactionAnimation } = useTransactionAnimation()

  return (
    <Container
      alignItems='center'
      alignSelf='center'
      customHeight='100%'
      isWrap
      justifyContent='center'
      padding={isMobile ? '50%' : '25%'}
    >
      <Column
        alignItems='center'
        justifyContent='center'
      >
        <Lottie
          animationData={transactionAnimation}
          autoPlay={false}
          loop={true}
          style={{
            width: isMobile ? '135px' : '156px',
            height: isMobile ? '139px' : '154.46px'
          }}
        />
        <Spacer.Horizontal size={24} />

        <Row width='315px'>
          <Text
            fontSize={isMobile ? 'xl' : '2xl'}
            fontWeight='medium'
            textAlign='center'
          >
            Estés donde estés, Unimarc está cerca de ti. ¡Te lo llevamos!
          </Text>
        </Row>
      </Column>
    </Container>
  )
}
