import { useRef, useState } from 'react'
import Image from 'next/image'
import {
  Column,
  DesktopModalWrapper,
  Text,
  MobileModalWrapper,
  Picture,
  Button,
  Row,
  Container,
  Spacer,
  Backbone
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen,
  getGlobalStyle,
  getBemId
} from '@smu-chile/pkg-unimarc-components/helpers'
import {
  deleteCard,
  trigger,
  useCards,
  useContentful,
  isValidArrayWithData
} from '@smu-chile/pkg-unimarc-hooks'
import { useSetLastCard } from 'hooks'
import { MODAL_IMAGES, TOAST_MESSAGES } from 'shared/constants'
import { getLastFourNumbersOfCard } from 'shared/helpers'
import { THandleToggleDeleteCardModal } from '../helpers'
import styles from '../styles.module.css'

interface IModalDeleteCardsProps {
  cardId: string
  handleToggleDeleteCardModal: THandleToggleDeleteCardModal
  handleGoBack?: () => void
}
const MODAL_DELETE_CARD_CONFIGS = {
  ID: {
    desktop: 'modalDeleteCardDesktop',
    mobile: 'modalDeleteCardMobile'
  },
  SIZES: {
    modal: {
      desktop: {
        maxHeight: '440px',
        minHeight: '440px'
      }
    },
    images: {
      pearImg: {
        desktop: {
          height: '188px',
          width: '408px'
        },
        mobile: {
          height: '180px',
          width: '378px'
        }
      }
    }
  },
  TEXTS: {
    confirmDelete: 'Si, eliminar',
    negateDelete: 'No, gracias'
  }
}

export const ModalDeleteCard = ({
  cardId,
  handleToggleDeleteCardModal,
  handleGoBack
}: IModalDeleteCardsProps) => {
  // UI
  const [isLoading, setIsLoading] = useState(false)
  // ARRANGE
  const { data: dataCards, refetch: refetchCards } = useCards({ version: 2 })
  const { handleSetLastCard } = useSetLastCard()
  const pearAsset = useContentful({
    id_contentful: 'pear-delete-modal-image',
    options: {
      'sys.id': MODAL_IMAGES.APPLE
    },
    type: 'assets'
  })
  const pearImage = pearAsset?.data?.['items']?.[0]?.fields?.file?.url
  const getCurrentCard =
    isValidArrayWithData(dataCards?.cards) &&
    dataCards.cards.find((card) => {
      return card.id === cardId
    })?.cardNumber
  const { current: getCurrentCardNumber } = useRef(
    getLastFourNumbersOfCard(getCurrentCard) || '****'
  )

  const handleDeleteCard = async () => {
    // start to show to user that process is happening
    setIsLoading(true)
    // request that current card be deleted
    const deleteCardResponse = await deleteCard(cardId)
    // if service is sucess (200) then
    if (deleteCardResponse?.status === 200) {
      await refetchCards()
      await handleSetLastCard()
      setIsLoading(false)
      trigger({
        eventType: 'checkoutToast',
        data: {
          show: true,
          success: true,
          toastMessage: TOAST_MESSAGES.success_deleted
        }
      })
    } else if (deleteCardResponse?.status === 409) {
      // cannot delete card because is using to membership
      trigger({
        eventType: 'cannotDeleteCard',
        data: { show: true }
      })
    } else {
      trigger({
        eventType: 'checkoutToast',
        data: {
          codeError: deleteCardResponse?.data?.code ?? 'unidentified',
          show: true,
          toastMessage: TOAST_MESSAGES.error_deleted
        }
      })
    }
    handleToggleDeleteCardModal(cardId, false)
  }

  return (
    <>
      <BigScreen>
        <DesktopModalWrapper
          blockId={MODAL_DELETE_CARD_CONFIGS.ID.desktop}
          bodyCustomClass={styles['container_body--deleteCard']}
          borderEdge='none'
          footerChildrenProps={{
            boxShadow: '0',
            default: false,
            children: (
              <>
                <Button
                  borderRadius={getGlobalStyle('--border-radius-xl')}
                  fontWeight='400'
                  fullWidth
                  id={getBemId(
                    MODAL_DELETE_CARD_CONFIGS.ID.desktop,
                    'continueButton',
                    'desktop'
                  )}
                  isLoading={isLoading}
                  label={MODAL_DELETE_CARD_CONFIGS.TEXTS.confirmDelete}
                  onClick={handleDeleteCard}
                  padding='8px 16px'
                  status='initial'
                />
                <Button
                  background={getGlobalStyle('--color-base-white')}
                  border={`1px solid ${getGlobalStyle('--color-base-black')}`}
                  borderRadius={getGlobalStyle('--border-radius-xl')}
                  color={getGlobalStyle('--color-base-black')}
                  fontWeight='500'
                  fullWidth
                  id={getBemId(
                    MODAL_DELETE_CARD_CONFIGS.ID.desktop,
                    'selectAnotherPaymentOptionButton',
                    'desktop'
                  )}
                  label={MODAL_DELETE_CARD_CONFIGS.TEXTS.negateDelete}
                  onClick={() => {
                    return handleToggleDeleteCardModal(cardId, true)
                  }}
                  padding='8px 16px'
                  type='secondary-gray'
                />
              </>
            )
          }}
          headerBackButtonProps={{
            onBack: handleGoBack,
            icon: {
              customSize: 20
            }
          }}
          headerCloseIcon={{
            customSize: 13,
            default: true
          }}
          headerTitleProps={{
            fontWeight: 'medium'
          }}
          modalConfigsProps={{
            isWindowBlocked: true,
            maxHeight: MODAL_DELETE_CARD_CONFIGS.SIZES.modal.desktop.maxHeight,
            minHeight: MODAL_DELETE_CARD_CONFIGS.SIZES.modal.desktop.minHeight,
            isOpen: true,
            toggle: handleToggleDeleteCardModal,
            toggleOutside: handleToggleDeleteCardModal
          }}
          styleProps={{
            padding: '0',
            customHeightModalContent: '100%'
          }}
        >
          <Container
            customHeight='100%'
            tagName='section'
          >
            <Column>
              <Row
                alignItems='center'
                id={getBemId(MODAL_DELETE_CARD_CONFIGS.ID.desktop, 'pera')}
              >
                {pearAsset.isLoading ? (
                  <Backbone
                    borderRadius={getGlobalStyle('--border-radius-lg')}
                    height={188}
                    width={100}
                  />
                ) : (
                  <Picture
                    alt='imagen-pera-borrar-tarjeta'
                    height={
                      MODAL_DELETE_CARD_CONFIGS.SIZES.images.pearImg.desktop
                        .height
                    }
                    nextImage={Image}
                    src={pearImage}
                    width={
                      MODAL_DELETE_CARD_CONFIGS.SIZES.images.pearImg.desktop
                        .width
                    }
                  />
                )}
              </Row>
              <Row alignItems='center'>
                <Column
                  alignItems='center'
                  tagName='article'
                >
                  <Text
                    fontSize='lg'
                    fontWeight='semibold'
                    lineHeight='normal'
                    textAlign='center'
                  >
                    Eliminar tarjeta
                  </Text>
                  <Spacer.Horizontal size={12} />
                  <Text textAlign='center'>
                    ¿Quieres eliminar tu tarjeta terminada en &nbsp;
                    <Text
                      fontWeight='medium'
                      id={getBemId(
                        MODAL_DELETE_CARD_CONFIGS.ID.desktop,
                        'cardNumber'
                      )}
                      type='span'
                    >{` ${getCurrentCardNumber}`}</Text>
                    ?
                  </Text>
                </Column>
              </Row>
            </Column>
          </Container>
        </DesktopModalWrapper>
      </BigScreen>
      <SmallScreen>
        <MobileModalWrapper
          blockId={MODAL_DELETE_CARD_CONFIGS.ID.mobile}
          body={
            <Container
              alignItems='center'
              customHeight='100%'
              tagName='section'
            >
              <Column margin='0 0 80px 0'>
                <Row
                  alignItems='center'
                  id={getBemId(MODAL_DELETE_CARD_CONFIGS.ID.mobile, 'pera')}
                  justifyContent='center'
                >
                  {pearAsset.isLoading ? (
                    <Column justifyContent='center'>
                      <Backbone
                        borderRadius={getGlobalStyle('--border-radius-lg')}
                        height={180}
                        width={100}
                      />
                      <Spacer.Horizontal size={12} />
                    </Column>
                  ) : (
                    <Picture
                      alt='imagen-pera-borrar-tarjeta'
                      height={
                        MODAL_DELETE_CARD_CONFIGS.SIZES.images.pearImg.mobile
                          .height
                      }
                      nextImage={Image}
                      src={pearImage}
                      width={
                        MODAL_DELETE_CARD_CONFIGS.SIZES.images.pearImg.mobile
                          .width
                      }
                    />
                  )}
                </Row>
                <Row alignItems='center'>
                  <Column
                    alignItems='center'
                    justifyContent='center'
                    tagName='article'
                  >
                    <Text
                      fontSize='lg'
                      fontWeight='semibold'
                      lineHeight='normal'
                      textAlign='center'
                    >
                      Eliminar tarjeta
                    </Text>
                    <Spacer.Horizontal size={12} />
                    <Text
                      display='contents'
                      textAlign='center'
                    >
                      ¿Quieres eliminar tu tarjeta terminada en
                      <Text
                        id={getBemId(
                          MODAL_DELETE_CARD_CONFIGS.ID.desktop,
                          'cardNumber'
                        )}
                        type='b'
                      >
                        {` ${getCurrentCardNumber}`}?
                      </Text>
                    </Text>
                  </Column>
                </Row>
              </Column>
            </Container>
          }
          customFooter={
            <Column>
              <Button
                borderRadius={getGlobalStyle('--border-radius-xl')}
                fullWidth
                id={getBemId(
                  MODAL_DELETE_CARD_CONFIGS.ID.desktop,
                  'continueButton',
                  'desktop'
                )}
                isLoading={isLoading}
                label={MODAL_DELETE_CARD_CONFIGS.TEXTS.confirmDelete}
                onClick={handleDeleteCard}
                padding='8px 16px'
                status='initial'
              />
              <Spacer.Horizontal size={16} />
              <Button
                background={getGlobalStyle('--color-base-white')}
                border={`1px solid ${getGlobalStyle('--color-base-black')}`}
                borderRadius={getGlobalStyle('--border-radius-xl')}
                color={getGlobalStyle('--color-base-black')}
                fontWeight='500'
                fullWidth
                id={getBemId(
                  MODAL_DELETE_CARD_CONFIGS.ID.desktop,
                  'selectAnotherPaymentOptionButton',
                  'desktop'
                )}
                label={MODAL_DELETE_CARD_CONFIGS.TEXTS.negateDelete}
                onClick={() => {
                  return handleToggleDeleteCardModal(cardId, true)
                }}
                padding='8px 16px'
                type='secondary-gray'
              />
            </Column>
          }
          dragProps={{
            draggButton: false
          }}
          hasShadow={false}
          headerCloseIconProps={{
            customSize: 13
          }}
          headerProps={{
            padding: '3px 16px'
          }}
          hideHeaderDivider={false}
          iconColor={getGlobalStyle('--color-base-black')}
          iconSize={20}
          isEnableButton
          modalConfigsProps={{
            openAnimation: true,
            fullScreenSticky: true,
            hiddenX: true,
            isAutoHeight: true,
            isOpen: true,
            isWindowBlocked: true,
            marginFullScreen: '1vh 0 0',
            stickyBottom: true,
            toggle: handleToggleDeleteCardModal,
            toggleOutside: handleToggleDeleteCardModal
          }}
          onClose={handleToggleDeleteCardModal}
          styleProps={{
            padding: '0',
            height: '100%',
            borderRadius: `${getGlobalStyle(
              '--border-radius-md'
            )} ${getGlobalStyle('--border-radius-md')} 0 0`
          }}
        />
      </SmallScreen>
    </>
  )
}
